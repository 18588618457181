export interface UserStateModel {
    id: number | null
    username: string | null
    salutation: string | null
    firstname: string | null
    lastname: string | null
    emailAddress: string | null
    role: string | null
    userStatus: string | null
    mustChangePassword: boolean | null
}

export class SetUser {
    static readonly type = '[User] Set user';
}

export class RemoveUser {
    static readonly type = '[User] Remove user';
}

export class ChangePassword {
    static readonly type = '[User] Change password';
    constructor(public payload: { oldPassword: string, newPassword: string }) {}
}