import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';

@Component({
	selector: 'app-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

	@ViewChild('captchaRef') captchaRef: any;
	
	resetForm: FormGroup
	success: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService
	) { }

	ngOnInit(): void {
		this.resetForm = this.formBuilder.group({
			username: new FormControl('', [Validators.required]),
			captcha: new FormControl(null, [Validators.required])
		})
	}

	errored(event: any): void {
		console.error(event);
		this.captchaRef.reset();
	}

	onSubmit(): void {
		this.authService.passwordReset(this.resetForm.get('username').value).subscribe({
				next: () => {
					this.success = true;
				},
				error: (error: any) => {
					this.success = false;
					this.captchaRef.reset();
					console.error(error);
				}
			});
	}
}
