import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from '@core/states/auth/auth.action';
import { SetUser } from '@core/states/user/user.action';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { RecaptchaComponent } from 'ng-recaptcha';
import { of, Subscription, switchMap } from 'rxjs';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

	@ViewChild('captchaRef') reCaptchaRef: any;

	loginForm: FormGroup
	hideVisibility: boolean = true;

	siteKey: string = environment.reCaptchaSiteKeyV2Invisible;

	subscription: Subscription = new Subscription();

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private snackBar: MatSnackBar,
		private store: Store
	) { }

	ngOnInit(): void {
		// https://github.com/DethAriel/ng-recaptcha/issues/123#issuecomment-426112101
		RecaptchaComponent.prototype.ngOnDestroy = function() {};

		this.loginForm = this.formBuilder.group({
			username: new FormControl('', [Validators.required]),
			password: new FormControl('', [Validators.required])
		})
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	resolved(captchaResponse: string) {
		if ( !captchaResponse ) { return; }
		this.login();
	}

	errored() {
		this.reCaptchaRef.reset();
	}

	onSubmit()	{
		if ( this.loginForm.invalid ) {
			this.snackBar.open('Bitte füllen Sie alle Felder aus.', '🚨', { duration: 2000, panelClass: ['error-snack'] });
			return;
		}
		this.reCaptchaRef.execute()
	}	


	login(): void {

		this.snackBar.open('Anmelden...', '', { duration: 2000, panelClass: ['info-snack'] });

		this.store.dispatch(new Login({
			username: this.loginForm.get('username')?.value,
			password: this.loginForm.get('password')?.value
		}))
			.pipe(
				switchMap(() => this.store.dispatch(new SetUser())),
				switchMap(() => of(this.router.navigate([this.activatedRoute.snapshot.queryParams['redirect_to'] || 'admin/home'])))
			)
			.subscribe({
				next: () => {
					this.snackBar.open('Authentifizierung erfolgreich.', '🛰️', { duration: 2000, panelClass: ['success-snack'] });
				},
				error: () => {
					this.reCaptchaRef.reset();
				}
			});
	}

}
