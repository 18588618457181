<!--<h1 mat-dialog-title>Detail</h1>-->
<div mat-dialog-content>
    <small>{{data.get('name').value}}<br>
    Kapazität: {{data.get('capacity').value}}</small>
    <!--
    <div *ngIf="remainingTime > 0" class="text-sm mt-3">
        In ca. {{ remainingTime <= 5000 ? (remainingTime | date: 'm \'Minute\'') : (remainingTime |date: 'm \'Minuten\'') }} können Sie wieder Belegungen für diesen Parkraum übermitteln.
    </div>
    <div *ngIf="data.get('blocked').value && !remainingTime" class="text-sm mt-3">
        Dieser Parkraum ist noch blockiert.
    </div>
    -->
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Schließen</button>
</div>