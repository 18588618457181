import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from '@shared/form-validators/must-match.validator';
import { ChangePassword } from '@core/states/user/user.action';
import { Store } from '@ngxs/store';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

	changePasswordForm: FormGroup

	hideVisibilityOld: boolean = true;
	hideVisibilityNew: boolean = true;
	hideVisibilityRepeat: boolean = true;

	constructor(
		private formBuilder: FormBuilder,
		private store: Store,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private snackBar: MatSnackBar
	) {}

	ngOnInit(): void {

		this.changePasswordForm = this.formBuilder.group({
			oldPassword: new FormControl('', [Validators.required]),
			newPassword: new FormControl('', [Validators.required]),
			repeatPassword: new FormControl('', [Validators.required, MustMatch('newPassword')]),
		})
	}

	onSubmit(): void {

		this.store.dispatch(new ChangePassword({
			oldPassword: this.changePasswordForm.get('oldPassword').value,
			newPassword: this.changePasswordForm.get('newPassword').value
		}))
		.subscribe(
			() => {
				this.snackBar.open('Passwort erfolgreich geändert.', '', { duration: 1500, panelClass: ['success-snack'] });
				const redirect = this.activatedRoute.snapshot.queryParams['redirect_to'] || `admin/home`;
				this.router.navigate([redirect]);
			}
		)
	}

}
