<h1>Hallo, {{me.username}}! 👋</h1>

<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">

	<mat-card matRipple [routerLink]="'/admin/count'" class="count-occupancies-card !flex-row items-center" *ngIf="roleService.allowedRoles([Role.PARKSPOTTER])">
		<div class="flex justify-center w-1/4">
			<mat-icon matListItemIcon>no_crash</mat-icon> 
		</div>
		<div>
			<mat-card-content>
				<p>
					Starte eine neue Zählung.
				</p>
			</mat-card-content>
			<mat-card-actions>
				<a mat-button [routerLink]="'/admin/count'">
					<mat-icon matListItemIcon>arrow_forward</mat-icon> Belegung zählen
				</a>
			</mat-card-actions>
		</div>
	</mat-card>
	
	<mat-card matRipple [routerLink]="'/admin/history'" class="history-card !flex-row items-center" *ngIf="roleService.allowedRoles([Role.DISPATCHER, Role.PARKSPOTTER])">
		<div class="flex justify-center w-1/4">
			<mat-icon matListItemIcon>timeline</mat-icon> 
		</div>
		<div>
			<mat-card-content> 
				<p>
					Hier findest du deinen Zählverlauf.
				</p>
			</mat-card-content>
			<mat-card-actions>
				<a mat-button [routerLink]="'/admin/history'">
					<mat-icon matListItemIcon>arrow_forward</mat-icon> Historie
				</a>
			</mat-card-actions>
		</div>
	</mat-card>

	<mat-card matRipple [routerLink]="'/admin/users'" class="users-card !flex-row items-center" *ngIf="roleService.allowedRoles([Role.DISPATCHER])">
		<div class="flex justify-center w-1/4">
			<mat-icon matListItemIcon>admin_panel_settings</mat-icon> 
		</div>
		<div>
			<mat-card-content>
				<p>
					Lade neue Parkspotter ein oder verwalte sie.
				</p>
			</mat-card-content>
			<mat-card-actions>
				<a mat-button [routerLink]="'/admin/users'">
					<mat-icon matListItemIcon>arrow_forward</mat-icon> Parkspotter verwalten
				</a>
			</mat-card-actions>
		</div>
	</mat-card>
	
	<mat-card matRipple [routerLink]="'/admin/me'" class="me-card !flex-row items-center" *ngIf="roleService.allowedRoles([Role.DISPATCHER, Role.PARKSPOTTER])">
		<div class="flex justify-center w-1/4">
			<mat-icon matListItemIcon>account_circle</mat-icon>
		</div>
		<div>
			<mat-card-content>
				<p>
					Bearbeite deine persönlichen Informationen.
				</p>
			</mat-card-content>
			<mat-card-actions>
				<a mat-button [routerLink]="'/admin/me'">
					<mat-icon matListItemIcon>arrow_forward</mat-icon> Mein Konto
				</a>
			</mat-card-actions>
		</div>
	</mat-card>

</div>