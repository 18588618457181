<div class="py-3 text-center" ngClass="{{data.userStatus}}">
    {{data.userStatus | translate}}
</div>

<div mat-dialog-content  class="!mb-5">

    <mat-card class="mb-2 p-4">

        <div class="flex flex-col">
            <b>{{data.username}}</b>
            {{data.salutation | translate}} {{data.firstname}} {{data.lastname}}
            <a href="mailto:{{data.emailAddress}}">{{data.emailAddress}}</a>
        </div>

    </mat-card>

    <div class="mb-8">
    
        <button *ngIf="!userIsLocked" 
            mat-flat-button
            [disabled]="data.userStatus === 'PENDING'"
            color="primary" 
            type="button"
            class="w-full mt-0 !h-12 uppercase"
            (click)="changeUserStatus('LOCKED', data.id)">
        <mat-icon [style.margin-right.rem]="0.5">person_off</mat-icon>Sperren
        </button>
        <button *ngIf="userIsLocked"
            mat-flat-button
            color="primary" 
            type="button"
            class="w-full mt-0 !h-12 uppercase"
            (click)="changeUserStatus('ACTIVE', data.id)">
        <mat-icon [style.margin-right.rem]="0.5">person</mat-icon>Entsperren
        </button>

        <div class="max-w-xs text-xs p-2" *ngIf="data.userStatus === 'PENDING'">
            Der Benutzer kann noch nicht gesperrt werden solange sein Status '{{data.userStatus | translate}}' ist.
        </div>

    </div>

    <mat-form-field class="w-full">
        <mat-label>Schreiben Sie 'LÖSCHEN'</mat-label>
        <input type="text" matInput [formControl]="confirmControl" cdkFocusInitial>
    </mat-form-field>

    <button 
        mat-flat-button 
        color="warn" 
        class="w-full -mt-4 !h-12 uppercase"
        [disabled]="!(confirmControl.value === 'LÖSCHEN')"
        (click)="deleteUser(data.id)">
        <mat-icon>person_remove</mat-icon>Parkspotter löschen
    </button>

    <div class="max-w-xs text-xs p-2">
        Schreiben Sie "LÖSCHEN" in obiges Feld, um den Nutzer endgültig und unwiderruflich zu löschen.
    </div>
    
</div>
<div mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>Schließen</button>
</div>
