import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PaginationChanged, PaginationModel, RemovePagination } from './pagination.action';

@State<PaginationModel>({
	name: 'historyPagination',
	defaults: { size: 5, page: 0 }
})
@Injectable()
export class HistoryPaginationState {
	@Selector()
	static getCurrentParams(state: PaginationModel) {
		return state;
	}

	@Action(PaginationChanged)
	PaginationChanged(ctx: StateContext<PaginationModel>, action: PaginationChanged) {
		const state = ctx.getState();
		const pagination = {
			size: action.urlParams.size ?? state.size,
			page: action.urlParams.page ?? state.page
		};
		ctx.setState(pagination);
	}

	@Action(RemovePagination)
	RemovePagination(ctx: StateContext<PaginationModel>) {
		ctx.setState(null);
	}
}