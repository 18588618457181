import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from 'app/app-routing.module';
import { environment } from '@environments/environment';

import { MaterialModule } from './layout/material/material.module';

import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from './guards/auth.guard';

import { AuthState } from './states/auth/auth.state';
import { UserState } from './states/user/user.state';

import { HttpXsrfInterceptor } from './interceptors/http-xsrf.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { HeaderComponent } from './layout/header/header.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { SpacesListState } from 'app/admin/count-occupancies/states/spaces-list.state';

import { HistoryPaginationState } from 'app/admin/history/states/pagination.state';
import { getGermanPaginatorIntl } from './intl/german-paginator.intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatNativeDateModule } from '@angular/material/core';



@NgModule({
	declarations: [
		HeaderComponent,
		SidenavComponent
	],
	imports: [
		CommonModule,
		AppRoutingModule,
		HttpClientModule,
		MaterialModule,
		NgxsModule.forRoot([
			AuthState,
			UserState,
			SpacesListState,
			HistoryPaginationState,
		], { developmentMode: !environment.production }),
		NgxsStoragePluginModule.forRoot(),
		NgxsReduxDevtoolsPluginModule.forRoot(),
		FormsModule, 
		ReactiveFormsModule,
		MatNativeDateModule,
		
	],
	exports: [
		MaterialModule,
		HeaderComponent,
		SidenavComponent
	],
	providers: [
		AuthGuard,
		{ provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
	]
})
export class CoreModule { }
