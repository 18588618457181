import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MustMatch } from '@shared/form-validators/must-match.validator';
import { DataService } from '@core/services/data.service';
import { SetUser } from '@core/states/user/user.action';
import { UserState } from '@core/states/user/user.state';
import { Store } from '@ngxs/store';
import { Subject, of, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'app-me',
	templateUrl: './me.component.html',
	styleUrls: ['./me.component.scss']
})
export class MeComponent implements OnInit, OnDestroy {

	meForm: FormGroup
	passwordForm: FormGroup
	
	hideVisibilityNew: boolean = true;
	hideVisibilityNewRepeat: boolean = true;
	hideVisibilityOld: boolean = true;

	salutationOptions = [
		{ 'name': 'Keine Anrede', 'value': 'NONE' },
		{ 'name': 'Frau', 'value': 'MS' },
		{ 'name': 'Herr', 'value': 'MR' },
		{ 'name': 'Divers', 'value': 'DIVERSE' }
	]

	destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private formBuilder: FormBuilder,
		private dataService: DataService,
		private store: Store,
		private snackBar: MatSnackBar
	) { }


	ngOnInit(): void {

		this.meForm = this.formBuilder.group({
			id: new FormControl(null),
			role: new FormControl(''),
			userStatus: new FormControl(''),
			emailAddress: new FormControl('', [Validators.required, Validators.email]),
			firstname: new FormControl(''),
			lastname: new FormControl(''),
			salutation: new FormControl(''),
			username: new FormControl('', [Validators.required])
		})

		this.passwordForm = this.formBuilder.group({	
			oldPassword: new FormControl('', [Validators.required]),
			newPassword: new FormControl('', [Validators.required]),
			newPasswordRepeat: new FormControl('', [MustMatch('newPassword')]),
		})

		const me = this.store.selectSnapshot((UserState.getCurrentUser));
		if (me) this.meForm.patchValue(me)

		this.passwordForm.get('newPassword').valueChanges
			.pipe(
				takeUntil(this.destroy$),
				switchMap(password => {
					const repeatPasswordValidators = password ? [Validators.required, MustMatch('newPassword')] : [];
					this.passwordForm.get('newPasswordRepeat').setValidators(repeatPasswordValidators);
					this.passwordForm.get('newPasswordRepeat').updateValueAndValidity();
					return of(password);
				})
			)
			.subscribe();

	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	onSubmit(): void {
		// Remove from form control
		// this.meForm.removeControl('repeatPassword');
		this.dataService.editMe(this.meForm.value).subscribe({
			next: () => {
				this.store.dispatch(new SetUser);
				this.snackBar.open('Kontodaten gespeichert.', '', { duration: 2000, panelClass: ['success-snack'] });
			},
			complete: () => {
				/*this.meForm.addControl('repeatPassword', this.formBuilder.control('', [MustMatch('password')]));
				this.meForm.get('repeatPassowrd').clearValidators();*/
			}
		})
	}

	onPasswordSubmit(): void {
		const newPassword = this.passwordForm.get('newPassword').value;
		const oldPassword = this.passwordForm.get('oldPassword').value;	
		this.dataService.changePassword(oldPassword, newPassword).subscribe({
			next: () => {
				this.snackBar.open('Passwort geändert.', '', { duration: 2000, panelClass: ['success-snack'] });
			}
		})	
	}

}
