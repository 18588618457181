<mat-sidenav-container fullscreen>

    <mat-sidenav #sidenav mode="over" role="navigation">

        <mat-nav-list class="main-navigation">

            <a mat-list-item tabindex="-1" [routerLink]="['home']" routerLinkActive="active" (click)="sidenav.close()"> 
                <mat-icon matListItemIcon>home</mat-icon> Dashboard
            </a>
            
            <a *ngIf="roleService.allowedRoles([Role.PARKSPOTTER])" mat-list-item tabindex="-1" [routerLink]="['count']" routerLinkActive="active" (click)="sidenav.close()">
                <mat-icon matListItemIcon>no_crash</mat-icon> Belegung zählen 
            </a>

            <a mat-list-item tabindex="-1" [routerLink]="['history']" routerLinkActive="active" (click)="sidenav.close()"> 
                <mat-icon matListItemIcon>timeline</mat-icon> Historie
            </a>
            
            <a *ngIf="roleService.allowedRoles([Role.DISPATCHER])" mat-list-item tabindex="-1" [routerLink]="['users']" routerLinkActive="active" (click)="sidenav.close()">
                <mat-icon matListItemIcon>admin_panel_settings</mat-icon> Benutzer
            </a>

            <mat-divider></mat-divider>
            
            <a mat-list-item tabindex="-1" [routerLink]="['me']" routerLinkActive="active" (click)="sidenav.close()">
                <mat-icon matListItemIcon>account_circle</mat-icon> Mein Konto
            </a>

            <a mat-list-item tabindex="-1" (click)="logout()" routerLinkActive="active" (click)="sidenav.close()">
                <mat-icon matListItemIcon>logout</mat-icon> Ausloggen
            </a>
            
            <!--
            <a mat-list-item tabindex="-1" [routerLink]="['/help']" routerLinkActive="active" (click)="sidenav.close()">
                <mat-icon matListItemIcon>help</mat-icon> Hilfe
            </a>

            
            <a class="mb-auto" mat-list-item tabindex="-1" (click)="sidenav.close()">
                <mat-icon matListItemIcon>arrow_back</mat-icon> Menü schließen
            </a>
            -->
      
          </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content>

        <app-header (toggleSidenav)="sidenav.toggle()" role="header"></app-header>

        <main class="max-w-screen-sm mx-auto p-4" role="main">
            <router-outlet></router-outlet>
        </main>

    </mat-sidenav-content>

</mat-sidenav-container>