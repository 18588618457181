import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RemoveSpacesList, SpacesListModel, UpdateSpacesList } from './spaces-list.action';


@State<SpacesListModel>({
	name: 'spacesList',
	defaults: {
		spacesList: []
	}
})
@Injectable()
export class SpacesListState {



	@Selector()
	static getSpacesList(state: any) {
		return state
	}

	@Action(UpdateSpacesList)
	UpdateSpacesList(ctx: StateContext<SpacesListModel>, action: UpdateSpacesList) {
		
		ctx.setState(action.payload);

	}

	@Action(RemoveSpacesList)
	RemoveSpacesList(ctx: StateContext<SpacesListModel>, action: RemoveSpacesList) {
		
		ctx.setState(null);

	}

}