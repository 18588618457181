import { Component, OnInit } from '@angular/core';
import { Role } from '@core/enums/role.enum';
import { RoleService } from '@core/services/role.service';
import { UserState } from '@core/states/user/user.state';
import { Store } from '@ngxs/store';
import { User } from '@shared/models/user.interface';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	Role = Role;
	me: User;

	constructor(
		private store: Store,
		public roleService: RoleService
	) { }

	ngOnInit(): void {
		this.me = this.store.selectSnapshot(UserState.getCurrentUser);
	}

}
