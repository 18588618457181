<mat-form-field formGroupName="selectedSpace" class="w-full" subscriptSizing="dynamic">
    <mat-label>Parkraum auswählen</mat-label>
    <input type="text" aria-label="Select space" matInput 
        formControlName="name" 
        [matAutocomplete]="auto"
        (keyup)="onKeyup()">
    <button *ngIf="selectedSpace.get('name')?.value && !isLoading" 
            matSuffix
            (click)="selectedSpace.reset()" 
            mat-icon-button 
            aria-label="Clear">
        <mat-icon>close</mat-icon>
    </button>
    <mat-spinner *ngIf="isLoading" matSuffix diameter="16" class="inline-flex mr-3"></mat-spinner>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let c of filteredSpaces | async" [value]="c.nameOptional" (onSelectionChange)="selectionChange(c)">
            {{c.nameOptional}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>