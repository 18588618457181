import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function MustMatch(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        if ( !control.parent ) return null;
    
        const matchingControl = control.parent.get(controlName);

        if ( !matchingControl ) return null;

        return control.value === matchingControl.value ? null : { matchValues: true };
    };
}