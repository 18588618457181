import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { Login, Logout, Validation, AuthStateModel, Revoke } from './auth.action';
import { AuthService } from '@core/services/auth.service';
import { of } from 'rxjs';
import { RemoveUser, SetUser } from '../user/user.action';
import { RemoveSpacesList } from 'app/admin/count-occupancies/states/spaces-list.action';
import { RemovePagination } from 'app/admin/history/states/pagination.action';

const authStateDefaults: AuthStateModel = {
    isLoggedIn: false
};

@State<AuthStateModel>({
    name: 'auth',
    defaults: authStateDefaults
})
@Injectable()
export class AuthState {
    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return !!state.isLoggedIn;
    }

    constructor(
        private authService: AuthService,
        private store: Store
    ) { }

    @Action(Login)
    login(ctx: StateContext<AuthStateModel>, action: Login) {
        return this.authService.authorize(action.payload.username, action.payload.password).pipe(
            tap(
                () => {
                    ctx.patchState({
                        isLoggedIn: true
                    });
                }
            )
        );
    }

    @Action(Validation)
    validate(ctx: StateContext<AuthStateModel>) {
        return this.authService.validate(false).pipe(
            tap(
                (data: any) => {
                    // console.log(data)
                    ctx.patchState({ isLoggedIn: true })
                    // this.store.dispatch(new SetUser())
                }
            ),
            catchError(
                () => {
                    ctx.setState({ isLoggedIn: false })
                    
                    this.store.dispatch([
                        new RemoveUser(), 
                        new RemoveSpacesList(), 
                        new RemovePagination()
                    ])

                    return of(false)
                }
            )
        );
    }

    @Action(Revoke)
    revoke(ctx: StateContext<AuthStateModel>) {
        return this.authService.revoke().pipe(
            tap(
                () => {
                    ctx.setState(authStateDefaults);
                }
            )
        );
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        ctx.setState(authStateDefaults);
    }

}