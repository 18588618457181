import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChangePwdGuard } from '@core/guards/change-password.guard';
import { AuthGuard } from '@core/guards/auth.guard';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

import { SidenavComponent } from '@core/layout/sidenav/sidenav.component';
import { HomeComponent } from './admin/home/home.component';
import { UsersComponent } from './admin/users/users.component';
import { CountOccupanciesComponent } from './admin/count-occupancies/count-occupancies.component';
import { HistoryComponent } from './admin/history/history.component';
import { MeComponent } from './admin/me/me.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { RoleGuard } from '@core/guards/role.guard';



const routes: Routes = [
	{ 
		path: '', 
		component: LoginComponent 
	},
	{ 
		path: 'login', 
		component: LoginComponent 
	},
	{ 
		path: 'register', 
		component: RegisterComponent 
	},
	{ 
		path: 'password-reset', 
		component: PasswordResetComponent 
	},
	{ 
		path: 'change-password', 
		component: ChangePasswordComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'admin', component: SidenavComponent, canActivate: [RoleGuard, AuthGuard, ChangePwdGuard],
		children: [{
			path: 'home',
			component: HomeComponent,
			canActivate: [RoleGuard, AuthGuard, ChangePwdGuard],
			loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
		}, {
			path: 'count',
			component: CountOccupanciesComponent,
			canActivate: [RoleGuard, AuthGuard, ChangePwdGuard],
			loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
		}, {
			path: 'history',
			component: HistoryComponent,
			canActivate: [RoleGuard, AuthGuard, ChangePwdGuard],
			loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
		}, { 
			path: 'me', 
			component: MeComponent, 
			canActivate: [RoleGuard, AuthGuard, ChangePwdGuard], 
			loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
		}, { 
			path: 'users',
			component: UsersComponent, 
			canActivate: [RoleGuard, AuthGuard, ChangePwdGuard], 
			loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
		}, { 
			path: 'help',
			component: UsersComponent, 
			canActivate: [RoleGuard, AuthGuard, ChangePwdGuard], 
			loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
		}, { 
			path: '**', 
			redirectTo: '/login', 
			pathMatch: 'full'
		}]
	},
	{ 
		path: '**', 
		redirectTo: '/login', 
		pathMatch: 'full'
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: true})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
