<div class="grid place-items-center content-center h-full">

    <form *ngIf="!success" [formGroup]="resetForm" id="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()" class="max-w-xs">

        <h1>Passwort zurücksetzen:</h1>

        <mat-form-field class="w-full">

            <mat-label>E-Mail Adresse oder Benutzername</mat-label>

            <input matInput type="text" 
                   formControlName="username"
                   name="username" 
                   tabindex="1" />
        </mat-form-field>

        <re-captcha formControlName="captcha" 
                    (errored)="errored($event)"
                    #captchaRef="reCaptcha">
        </re-captcha>

        <button type="submit" 
                name="submit" 
                mat-flat-button 
                color="primary" 
                class="w-full !h-14 mt-4" 
                tabindex="3"
                [disabled]="resetForm.invalid">
            E-Mail zusenden
        </button>

    </form>

    <div *ngIf="success" class="max-w-xs flex flex-col border border-black border-solid rounded p-4">
        Sie haben eine E-Mail mit neuem Passwort zugesendet bekommen. Bitte überprüfen Sie Ihren Posteingang.
    </div>

    <p class="!mt-3">Zurück zum <a [routerLink]="'/login'">Login</a>.</p>

</div>