import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Role } from '@core/enums/role.enum';
import { Logout, Revoke } from '@core/states/auth/auth.action';
import { AuthState } from '@core/states/auth/auth.state';
import { UserState } from '@core/states/user/user.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {

	private allowedRules: string[] = [
		Role.PARKSPOTTER,
		Role.DISPATCHER
	]

	constructor(
        private store: Store,
        private router: Router,
		private snackBar: MatSnackBar,
    ) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		// Check if AuthGuard is activated
		const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

		if ( !isAuthenticated ) {
			return false;
		}
		  
		const userRole = this.store.selectSnapshot(UserState.getCurrentUserRole);

		if ( !this.allowedRules.includes(userRole) ) {

			this.store.dispatch([new Logout, new Revoke])
			
			this.router.navigate(['/'])

			this.snackBar.open(
				'Keine ausreichende Berechtigung. Bitte wenden Sie sich an den Support.',
				'',
				{ duration: 5000, panelClass: 'error-snack' }
			);

            return false;
		}
		
		return true;
	}

}
