import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '@core/services/data.service';

@Component({
	selector: 'app-user-detail-dialog',
	templateUrl: './user-detail-dialog.component.html',
	styleUrls: ['./user-detail-dialog.component.scss']
})
export class UserDetailDialogComponent implements OnInit {

	confirmControl = new FormControl('');

	userIsLocked: boolean

	constructor(
		private dataService: DataService,
		private snackBar: MatSnackBar,
		private dialogRef: MatDialogRef<UserDetailDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit(): void {

		switch (this.data.userStatus) {
			case 'PENDING':
				this.userIsLocked = false;
				break;
			case 'LOCKED':
				this.userIsLocked = true;
				break;
			case 'ACTIVE':
				this.userIsLocked = false;
				break;
			default:
				this.userIsLocked = true;
		}	
	}
	
	changeUserStatus(userStatus: string, id: number): void {

		this.data.userStatus = userStatus;

		this.dataService.editUser(this.data, id).subscribe((data: any) => {
			this.dialogRef.close({ data: 'success' })
		})
	}

	deleteUser(id: number): void {
		
		this.dataService.deleteUser(id).subscribe(() => {
			this.snackBar.open(
				'Benutzer erfolgreich gelöscht.',
				'Schließen', 
				{ duration: 5000, panelClass: ['success-snack'] }
			)
			this.dialogRef.close({ data: 'success' })
		})
	}


}
