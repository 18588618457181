import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Space } from '@shared/models/space.interface';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DataService {

	constructor(
		private http: HttpClient
	) { }

	/**
	 * user-controller
	 */
	getMe(): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/users/me`)
	}

	editMe(me: any): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/v2/users/me`, me)
	}

	getUsers(): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/users`)
	}

	editUser(user: any, id: number): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/v2/users/${id}`, user)
	}

	changePassword(oldPassword: string, newPassword: string): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/v2/users/me/password`, {
			newPassword: newPassword,
			oldPassword: oldPassword
		})
	}

	deleteUser(id: number): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/v2/users/${id}`)
	}

	inviteUser(emailAddress: any): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/v2/users/invite`, {}, {
			params: {emailAddress: emailAddress}
		})
	} 

	registerUser(isInvited: boolean, user: any): Observable<any> {
		const inviteUrl: string = isInvited ? '/v2/users/register/invited' : '/v2/users/register/new'
		return this.http.post<any>(`${environment.apiUrl}${inviteUrl}`, user)
	}

	/**
	 * spaces-controller
	 */
	getSpaces(params: any): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/spaces`, {
			params: params
		})
	}

	/**
	 * parkspotting-controller
	 */
	getCountedOccupancies(params: any): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/spaces/countedOccupancies`, {
			params: params
		})
	}

	postCountedOccupancies(space: Space): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/v2/spaces/${space.id}/countedOccupancies`, {
			occupancyCounted: space.count,
			timestamp: space.timestamp	
		})
	}
}
