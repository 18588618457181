import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetUser, RemoveUser, UserStateModel, ChangePassword } from './user.action';
import { DataService } from '@core/services/data.service';
import { tap } from 'rxjs/operators';

@State<UserStateModel>({
	name: 'user',
	defaults: {
		id: null,
		username: null,
		salutation: null,
		firstname: null,
		lastname: null,
		emailAddress: null,
		role: null,
		userStatus: null,
		mustChangePassword: null
	}
})
@Injectable()
export class UserState {
	
	@Selector()
	static getCurrentUserRole(state: UserStateModel) {
		return state.role;
	}

	@Selector()
	static getCurrentUser(state: UserStateModel) {
		return state;
	}

	@Selector()
	static mustChangePassword(state: UserStateModel) {
		return state.mustChangePassword;
	}

	constructor(
		private dataService: DataService
	) { }

	@Action(SetUser)
	setUser(ctx: StateContext<UserStateModel>) {

		return this.dataService.getMe().pipe(
			tap(
				(data) => {
					ctx.patchState({
						id: data.id,
						username: data.username,
						salutation: data.salutation,
						firstname: data.firstname,
						lastname: data.lastname,
						emailAddress: data.emailAddress,
						role: data.role,
						userStatus: data.userStatus,
						mustChangePassword: data.mustChangePassword
					});
				}
			)
		);
	}

	@Action(RemoveUser)
	removeUser(ctx: StateContext<UserStateModel>) {
		ctx.setState(null);
	}

	@Action(ChangePassword)
	mustChangePassword(ctx: StateContext<UserStateModel>, action: any) {
		return this.dataService.changePassword(
			action.payload.oldPassword, 
			action.payload.newPassword
		).pipe(
			tap(() => {
					ctx.patchState({
						mustChangePassword: false
					});
				}
			)
		);
	}

}