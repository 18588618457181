export interface PaginationModel {
    size: number | undefined
    page: number | undefined
}

export class PaginationChanged {
    static readonly type = '[History Table] Pagination Changed';
    constructor(
        public urlParams: any
    ) {}   
}

export class RemovePagination {
    static readonly type = '[History Table] Remove Pagination';
}
