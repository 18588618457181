import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Validation } from '@core/states/auth/auth.action';
import { Store } from '@ngxs/store';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'parkspotting';

	constructor(
		private router: Router,
		private store: Store
	) {
		this.validateCookie();
	}

	ngOnInit(): void {}

	validateCookie(): void {

		this.store.dispatch(new Validation()).subscribe(
			() => {
				if ( this.router.url === '/login' || this.router.url == '/' ) {
					this.router.navigate([`admin/home`])
				}
			}
		)

	}

}
