import { AbstractControl, ValidatorFn, FormControl } from '@angular/forms';

export function maxCount(capacity: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const input = control.value;
        if (input && input > capacity) {
            return { maxCount: true };
        }
        return null;
    };
}
