<div class="grid place-items-center content-center h-full">

    <form [formGroup]="changePasswordForm" id="ngForm" (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" class="max-w-xs" >

        <h1>Passwort ändern:</h1>

        <mat-form-field class="w-full">

            <mat-label>Altes Passwort</mat-label>
                
            <input matInput type="password" 
                formControlName="oldPassword"
                type="{{hideVisibilityOld ? 'password' : 'text'}}" 
                name="oldPassword" 
                tabindex="2" />

            <mat-icon *ngIf="changePasswordForm.get('oldPassword')?.value" 
                    matSuffix
                    (click)="changePasswordForm.get('oldPassword')?.reset()"
                    class="cursor-pointer !pr-0">
                close
            </mat-icon>

            <mat-icon matSuffix 
                    (click)="hideVisibilityOld = !hideVisibilityOld" 
                    class="cursor-pointer">
                {{hideVisibilityOld ? 'visibility_off' : 'visibility'}}
            </mat-icon>
            
        </mat-form-field>

        <mat-form-field class="w-full">

            <mat-label>Neues Passwort</mat-label>
                
            <input matInput type="password" 
                formControlName="newPassword"
                type="{{hideVisibilityNew ? 'password' : 'text'}}" 
                name="newPassword" 
                tabindex="2" />

            <mat-icon *ngIf="changePasswordForm.get('newPassword')?.value" 
                    matSuffix
                    (click)="changePasswordForm.get('newPassword')?.reset()"
                    class="cursor-pointer !pr-0">
                close
            </mat-icon>

            <mat-icon matSuffix 
                    (click)="hideVisibilityNew = !hideVisibilityNew" 
                    class="cursor-pointer">
                {{hideVisibilityNew ? 'visibility_off' : 'visibility'}}
            </mat-icon>
            
        </mat-form-field>

        <mat-form-field class="w-full">

            <mat-label>Passwort wiederholen</mat-label>
                
            <input matInput type="password" 
                formControlName="repeatPassword"
                type="{{hideVisibilityRepeat ? 'password' : 'text'}}" 
                name="repeatPassword" 
                tabindex="2" />

            <mat-icon *ngIf="changePasswordForm.get('repeatPassword')?.value" 
                    matSuffix
                    (click)="changePasswordForm.get('repeatPassword')?.reset()"
                    class="cursor-pointer !pr-0">
                close
            </mat-icon>

            <mat-icon matSuffix 
                    (click)="hideVisibilityRepeat = !hideVisibilityRepeat" 
                    class="cursor-pointer">
                {{hideVisibilityRepeat ? 'visibility_off' : 'visibility'}}
            </mat-icon>

            <mat-error *ngIf="changePasswordForm.get('repeatPassword').hasError('matchValues')">
                Die Passwörter stimmen nicht überein.
            </mat-error>
            
        </mat-form-field>

        
        <button type="submit" 
                name="submit" 
                mat-flat-button 
                color="primary" 
                class="w-full !h-14 uppercase">
            Senden
        </button>

    </form>

</div>