import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'translate'
})
export class TranslatePipe implements PipeTransform {

	private terms: any = {
		'MR': 'Herr',
		'MS': 'Frau',
		'DIVERSE': '',
		'NONE': '',
		'ACTIVE': 'Aktiv',
		'PENDING': 'Wartend',
		'DELETED': 'Gelöscht',
		'LOCKED': 'Gesperrt'
	}

	transform(value: string): string {

		return this.terms[value] || value;

	}

}
