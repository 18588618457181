import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CustomErrorStateMatcher } from '@shared/form-validators/error-state-matcher.validator';
import { DataService } from '@core/services/data.service';
import { UserDetailDialogComponent } from './user-detail-dialog/user-detail-dialog.component';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

	emailFormControl = new FormControl('', [Validators.required, Validators.email]);

	matcher = new CustomErrorStateMatcher();

	displayedColumns: string[] = ['userStatus', 'user' ];
	dataSource: MatTableDataSource<any>;

	@ViewChild(MatAccordion) accordion: MatAccordion;

	constructor(
		private dataService: DataService,
		public dialog: MatDialog,
		private snackBar: MatSnackBar,
	) {}

	ngOnInit(): void {
		this.updateUserList();
	}

	updateUserList(): void {
		this.dataService.getUsers().subscribe((data: any) => {
			// DISPATCHER already gets filtered PARKSPOTTER from backend, but just in case role is ADMIN
			const users = data.content.filter((user: any) => user.role === 'PARKSPOTTER')
			this.dataSource = new MatTableDataSource(users)
		})	
	}

	onSubmit(): void {
		this.dataService.inviteUser(this.emailFormControl.value).subscribe((data: any) => {
			
			this.snackBar.open(
				`Einladung wurde erfolgreich an ${data.emailAddress} versendet.`, 
				'Schließen', 
				{ duration: 5000, panelClass: ['success-snack'] }
			)
			
			this.emailFormControl.reset();
			this.emailFormControl.setErrors(null);
			this.emailFormControl.markAsPristine();
			this.emailFormControl.markAsUntouched();

			this.updateUserList();
		})
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openUserDialog(user: any) {
		let dialogRef = this.dialog.open(UserDetailDialogComponent, { data: user });
		// No need to unsubscribe as the observable itself completes
		dialogRef.afterClosed().subscribe((res) => {
			if ( res && res.data === 'success' ) this.updateUserList();
		});
	}


}
