export interface AuthStateModel {
    isLoggedIn: boolean;
}

export class Login {
    static readonly type = '[Auth] Login';
    constructor(public payload: { username: string; password: string }) { }
}

export class Validation {
    static readonly type = '[Auth] Validate';
}

export class Logout {
    static readonly type = '[Auth] Logout';
}

export class Revoke {
    static readonly type = '[Auth] Revoke';
}