export interface SpacesList {
    id: number | undefined
    capacity: number | undefined
    name: string | undefined
}

export interface SpacesListModel {
	spacesList: SpacesList[]
}

export class UpdateSpacesList {
    static readonly type = '[Spaces List] Update Spaces List';
    constructor(public payload: any) {}   
}

export class RemoveSpacesList {
    static readonly type = '[Spaces List] Remove Spaces List';
    constructor() {}   
}
