<h1 class="inline-flex items-center justify-start">
    <mat-icon class="mr-2">admin_panel_settings</mat-icon>
    Benutzer
</h1>

<!--
<div class="example-action-buttons">
    <button mat-button (click)="accordion.openAll()">Alle öffnen</button>
    <button mat-button (click)="accordion.closeAll()">Alle schließen</button>
</div>
-->

<mat-accordion class="example-headers-align" multi>

    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title class="min-w-[13rem]">
                Einladung senden
            </mat-panel-title>
            <mat-panel-description class="justify-end">
                <mat-icon>mail</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <form class="my-2" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
            <mat-form-field class="w-full">
                <mat-label>E-Mail Adresse</mat-label>
                <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher">
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                    Keine gültige E-Mail Adresse
                </mat-error>
            </mat-form-field>
            <button mat-flat-button color="primary" class="w-full mt-0 !h-12 uppercase"
                [disabled]="emailFormControl.invalid || emailFormControl.pristine">
                <mat-icon>send</mat-icon> Senden
            </button>
        </form>

    </mat-expansion-panel>

    <mat-expansion-panel expanded>

        <mat-expansion-panel-header>
            <mat-panel-title>
                Nutzerverwaltung
            </mat-panel-title>
            <mat-panel-description class="justify-end">
                <mat-icon>manage_accounts</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-form-field class="w-full">
            <mat-label>Suchen</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>

        <mat-table [dataSource]="dataSource" class="mat-elevation-z0 -mx-[1.5rem] w-[calc(100%+3rem)] min-h-[7rem] table-fixed">

            <!-- Position Column
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="!pr-0"> <mat-icon>edit</mat-icon> </td>
            </ng-container> -->

            <!-- Name Column -->
            <ng-container matColumnDef="userStatus">
                <mat-header-cell *matHeaderCellDef class="flex-none w-28"> Status </mat-header-cell>
                <mat-cell *matCellDef="let element" class="flex-none w-28"> 
                    <span class="status-badge" ngClass="{{element.userStatus}}">
                        {{element.userStatus | translate}}
                    </span> 
                </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="user">
                <mat-header-cell mat-header-cell *matHeaderCellDef> Benutzer </mat-header-cell>
                <mat-cell *matCellDef="let element"> 
                    <div>
                        <div><b>{{element.username}}</b></div> 
                        <div>{{element.emailAddress}}</div>
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" class="font-white bg-dark"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="odd:bg-slate-50 cursor-pointer" (click)="openUserDialog(row)" matRipple></mat-row>

            <!-- Row shown when there is no matching data. -->
            <ng-container *ngIf="dataSource?.data.length">
                <mat-row class="mat-row text-center align-middle" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                        Leider nichts gefunden mit "{{input.value}}".
                    </td>
                </mat-row>
            </ng-container>

            <mat-row class="mat-row text-center align-middle p-2" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                    Sie haben noch keine Parkspotter eingeladen.
                </td>
            </mat-row>

        </mat-table>

    </mat-expansion-panel>

</mat-accordion>