import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Role } from '@core/enums/role.enum';
import { RoleService } from '@core/services/role.service';
import { Revoke } from '@core/states/auth/auth.action';
import { RemoveUser } from '@core/states/user/user.action';
import { Store } from '@ngxs/store';
import { RemoveSpacesList } from 'app/admin/count-occupancies/states/spaces-list.action';
import { RemovePagination } from 'app/admin/history/states/pagination.action';
import { switchMap } from 'rxjs';

@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {

	Role = Role;

	constructor(
		private router: Router,
		private store: Store,
		private snackBar: MatSnackBar,
		public roleService: RoleService
	) { }

	logout(): void {
		this.store.dispatch(new Revoke())
		.pipe(
			switchMap(() => {
				return this.store.dispatch([
					new RemoveUser(), 
					new RemoveSpacesList(), 
					new RemovePagination()
				]);
			}
		)).subscribe(() => {
			this.snackBar.open('Erfolgreich ausgeloggt.', '👋', { duration: 2000, panelClass: ['success-snack'] });
			this.router.navigate([`/login`])
		})
	}

}
