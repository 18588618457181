import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserState } from '@core/states/user/user.state';


@Injectable({
	providedIn: 'root'
})
export class RoleService {
	constructor(private store: Store) { }
	// Function to check if the current user has any of the specified roles
	allowedRoles(requiredRoles: string[]): boolean {
		const currentUserRole: string = this.store.selectSnapshot<string>(UserState.getCurrentUserRole);
		return requiredRoles.includes(currentUserRole);
	}
}

