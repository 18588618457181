import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Space } from '@shared/models/space.interface';
import { DataService } from '@core/services/data.service';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
	selector: 'app-spaces-autocomplete',
	templateUrl: './spaces-autocomplete.component.html',
	styleUrls: ['./spaces-autocomplete.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective }
	],
	encapsulation : ViewEncapsulation.None,
})
export class SpacesAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

	selectedSpace: FormGroup
	filteredSpaces: Observable<Space[]>;
	subscription: Subscription;
	hasChanged: boolean = false
	isLoading: boolean = false

	constructor(
		private dataService: DataService,
		private rootForm: FormGroupDirective
	) { }

	ngOnInit(): void {
		this.selectedSpace = this.rootForm.control.get('selectedSpace') as FormGroup;

		this.filteredSpaces = this.selectedSpace.valueChanges.pipe(
			startWith(this.selectedSpace.value),
			debounceTime(500),
			tap(() => this.isLoading = true),
			distinctUntilChanged(),
			switchMap((value: any) => this._filter(value))
		)
	}

	ngAfterViewInit(): void {
		/** Empty input if no option was selected, this triggers required validator
		 *  and forces the user to take one of the suggested options
		 */
		this.subscription = this.autocompleteTrigger.panelClosingActions.subscribe((e) => {
			if (!(e && e.source) && this.hasChanged) {
				this.selectedSpace.get('name').patchValue('', { emitEvent: false });
				this.autocompleteTrigger.closePanel();
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	selectionChange(item: Space) {
		// patch needed values
		this.selectedSpace.get('id').patchValue(item.id);
		this.selectedSpace.get('capacity').patchValue(item.numberParkingPlaces);
		this.selectedSpace.get('geoLocation').patchValue(item.geoLocation);
		// remove "P" from label
		const numericalLabel = parseInt(item.label.substring(1));
		this.selectedSpace.get('label').patchValue(numericalLabel);
		// reset change detector
		this.hasChanged = false
	}

	onFocus() {
		this.filteredSpaces = this.selectedSpace.valueChanges.pipe(
			startWith(this.selectedSpace.value),
			debounceTime(500),
			tap(() => this.isLoading = true),
			distinctUntilChanged(),
			switchMap((value: any) => this._filter(value))
		)
	}

	onKeyup() {
		// changes has been made by user
		this.hasChanged = true
	}

	private _filter(value: string | Space): Observable<Space[]> {

		let filterValue: string;

		if (typeof value === 'string') {
			filterValue = value;
		} else {
			filterValue = value.name || '';
		}

		const params = Object.fromEntries(
			Object.entries({
			  size: 1000,
			  // source: 'DBBAHNPARK',
			  // visibility: 'ONLINE',
			  // responsibility: 'DBBAHNPARK',
			  name: filterValue
			}).filter(([_, value]) => value != null && value)
		);

		return this.dataService.getSpaces(params).pipe(
			map((response: any) => response.content.filter((item: Space) => item.name.toLowerCase().includes(filterValue.toLowerCase()))),
			finalize(() => this.isLoading = false)
		)
	}


}
