import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableModule } from '@angular/material/table';


import { DataService } from '@core/services/data.service';
import { Store } from '@ngxs/store';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { Space } from '@shared/models/space.interface';
import { catchError, forkJoin, map, of, Subject, takeUntil } from 'rxjs';
import { SpaceDetailsDialogComponent } from './space-details-dialog/space-details-dialog.component';
import { UpdateSpacesList } from './states/spaces-list.action';
import { SpacesListState } from './states/spaces-list.state';
import { maxCount } from './validators/max-count.validator';
import { MatExpansionPanel } from '@angular/material/expansion';


@Component({
	selector: 'app-count-occupancies',
	templateUrl: './count-occupancies.component.html',
	styleUrls: ['./count-occupancies.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation : ViewEncapsulation.None,
})
export class CountOccupanciesComponent implements OnInit, OnDestroy {

	form: FormGroup;

	displayedColumns: string[] = ['count', 'name', 'capacity', 'deleteRow'];

	unsubscribe$: Subject<void> = new Subject<void>();

	errorMessage: HttpErrorResponse;

	hasSelectedSpace: boolean = false;

	@ViewChild(MatTable) table: MatTable<MatTableModule>;
	@ViewChild('panel') panel: MatExpansionPanel;

	constructor(
		private dataService: DataService,
		private formBuilder: FormBuilder,
		private store: Store,
		public dialog: MatDialog,
		private snackBar: MatSnackBar,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		
		this.form = this.formBuilder.group({
			selectedSpace: this.formBuilder.group({
				id: [null],
				name: [''],
				capacity: [null],
				geoLocation: {
					latitude: [null],
					longitude: [null]
				},
				label: ['']
			}),
			spacesList: this.formBuilder.array([])
		});

		const spacesList = this.store.selectSnapshot(SpacesListState.getSpacesList);
		
		if ( spacesList ) spacesList.map((space: Space) => this.spacesList.push(this.createSpaceForm(space)))

		this.form.get('spacesList').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((spacesList) => {
				this.store.dispatch(new UpdateSpacesList(spacesList))
			})

		this.form.get('selectedSpace').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((selectedSpace) => {
				if ( selectedSpace.id ) {
					this.hasSelectedSpace = true
				} else {
					this.hasSelectedSpace = false
					this.panel.close();
				}
			})

	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	get spacesList(): FormArray {
		return this.form.controls['spacesList'] as FormArray;
	}

	addItem(): void {
		const selectedSpace = this.form.get('selectedSpace').value;
		const existingSpace = this.spacesList.value.find((space: any) => space.id === selectedSpace.id);
	  
		if ( existingSpace ) {
		  this.snackBar.open(
			'Dieser Parkraum wurde bereits hinzugefügt.', 
			'Schließen', 
			{ duration: 2500, panelClass: ['error-snack'] }
		  );
		} else {
		  const spaceForm = this.createSpaceForm(selectedSpace);
		  this.spacesList.push(spaceForm);
		  this.table.renderRows();
		  this.form.get('selectedSpace').reset();
		}
	}

	removeItem(id: number): void {
		this.spacesList.removeAt(this.spacesList.value.findIndex((space: Space) => space.id === id));
		this.table.renderRows();
	}

	removeAllItems(): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
            autoFocus: true,
			data: {
				title: `Alles löschen?`,
				text: `Sie wollen alle hinzugefügten Parkräume aus der Liste löschen?`,
				// doubleConfirm: true,
				confirmationText: `LÖSCHEN`,
			}
        });

		dialogRef.afterClosed().subscribe(res => {
			if ( res.data === 'confirmed' ) {
				this.spacesList.clear();
				this.table.renderRows();
			}	
		});
	}
	
	private createSpaceForm(selectedSpace: Space): FormGroup {
		const spaceForm = this.formBuilder.group({
			id: selectedSpace.id,
			name: selectedSpace.name,
			capacity: selectedSpace.capacity,
			count: new FormControl(selectedSpace.count, [
				Validators.required,
				maxCount(selectedSpace.capacity)
			]),
			timestamp: selectedSpace.timestamp,
			blocked: selectedSpace.blocked
		});
	
		spaceForm.get('count').valueChanges.subscribe(() => {
			spaceForm.get('timestamp').setValue(new Date().toISOString());
		});
	
		return spaceForm;
	}

	onSubmit(): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
            autoFocus: true,
			maxWidth: '98vw',
			data: {
				title: `Zusammenfassung`,
				text: `
					<p>Sie können pro Prakraum nur alle <b>5 Minuten</b> eine gezählte Belegung übermitteln.</p>
					<table class="min-w-full text-center">
						<thead class="border-b bg-gray-800">
							<tr>
								<th scope="col" class="text-sm font-medium text-white px-3 py-2">Parkraum</th>
								<th scope="col" class="text-sm font-medium text-white px-3 py-2">Gezählt</th>
							</tr>
							</thead class="border-b">
							<tbody>
							${this.form.get('spacesList').value.map((space: Space) => 
								`<tr class="odd:bg-white bg-gray-100 border-b">
									<td class="px-3 py-2 text-sm font-medium text-gray-900 text-left">${space.name}</td>
									<td class="text-sm text-gray-900 font-light px-3 py-2 text-right">${space.count}</td>
								</tr>
							`).join('')}
						</tbody>
					</table>
					`,
				// doubleConfirm: true,
				confirmationText: `JETZT ÜBERMITTELN`,
				buttonColor: 'primary'
			}
        });

		dialogRef.afterClosed().subscribe(res => {
			
			if ( res.data === 'confirmed' ) {
				
				const requests$ = this.spacesList.value.map(
					(space: Space) => this.dataService.postCountedOccupancies(space).pipe(
						catchError((error: HttpErrorResponse) => of(error))
					)
				)

				forkJoin(requests$).pipe(
					map((responses: any) => {
					  	// handle responses
						responses.forEach((response: Space | HttpErrorResponse, index: number) => {
							// either way the space submission is now blocked							
							this.spacesList.at(index).get('blocked').patchValue(true)
							// if it is an error response
							if ( response instanceof HttpErrorResponse ) {
								// set error in form array
								this.spacesList.at(index).setErrors({
									'serverError': true, 
									message: `${response.error.userMessages[0]}`
								});
							} else {
								this.spacesList.at(index).get('timestamp').setValue(response.timestamp)
							}
						});

						// trigger validation
						this.changeDetectorRef.markForCheck();
								
						const success = responses
							.filter((response: Space | HttpErrorResponse) => !(response instanceof HttpErrorResponse));

						const error = responses
							.filter((response: Space | HttpErrorResponse) => response instanceof HttpErrorResponse);

						return {success, error}
					})
				).subscribe((response: any) => {

					if ( response.error.length === 0 ) {
						this.snackBar.open(
							'Alle Zählungen wurden erfolgreich übermittelt.', 
							'Schließen', 
							{ duration: 4500, panelClass: ['success-snack'] }
						)
					}
					if ( response.success.length !== 0 && response.error.length !== 0 ) {
						this.snackBar.open(
							'Es konnten nicht alle Zählungen erfolgreich übermittelt werden.', 
							'Schließen', 
							{ duration: 4500, panelClass: ['alert-snack'] }
						)
					}

				})
			}

		});
	}

	openDetailDialog(element: FormGroup): void {
        this.dialog.open(SpaceDetailsDialogComponent, { 
            data: element
        });
    }

}
