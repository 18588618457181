<h1 class="inline-flex items-center justify-start">
    <mat-icon class="mr-2">person</mat-icon>
    Mein Konto
</h1>

<form [formGroup]="meForm" id="meForm" (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
    
    <mat-card class="mb-4 p-3">

        <mat-form-field class="w-full pt-5">
            <mat-label>E-Mail Adresse</mat-label>
            <input matInput formControlName="emailAddress">
            <mat-error *ngIf="meForm.get('emailAddress').hasError('email')">
                Bitte geben Sie eine valide E-Mail Adresse ein.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Benutzername</mat-label>
            <input matInput formControlName="username" required>
        </mat-form-field>

        <mat-form-field class="w-full pt-5">
            <mat-label>Anrede</mat-label>
            <mat-select formControlName="salutation">
                <mat-option *ngFor="let option of salutationOptions" [value]="option.value">{{option.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Vorname</mat-label>
            <input matInput formControlName="firstname">
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Nachname</mat-label>
            <input matInput formControlName="lastname">
        </mat-form-field>

		<button mat-flat-button color="primary" type="submit" class="!h-14" [disabled]="meForm.invalid">
			Speichern
		</button>

	</mat-card>

</form>

<h1 class="inline-flex items-center justify-start !mt-4">
    <mat-icon class="mr-2">lock</mat-icon>
    Passwort ändern
</h1>

<form [formGroup]="passwordForm" id="passwordForm" (submit)="onPasswordSubmit()" (keydown.enter)="$event.preventDefault()">

    <mat-card class="p-3">

        <mat-form-field class="w-full pt-5">
            <mat-label>Aktuelles Passwort</mat-label>
            <input matInput formControlName="oldPassword" type="{{hideVisibilityOld ? 'password' : 'text'}}" >
            <mat-icon *ngIf="passwordForm.get('oldPassword')?.value" 
                      matSuffix
                      (click)="passwordForm.get('oldPassword')?.reset()"
                      class="cursor-pointer !pr-0">
                close
            </mat-icon>

            <mat-icon matSuffix 
                      (click)="hideVisibilityOld = !hideVisibilityOld" 
                      class="cursor-pointer">
                {{hideVisibilityOld ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Neues Passwort</mat-label>
            <input matInput formControlName="newPassword" type="{{hideVisibilityNew ? 'password' : 'text'}}" >
            <mat-icon *ngIf="passwordForm.get('newPassword')?.value" 
                      matSuffix
                      (click)="passwordForm.get('newPassword')?.reset()"
                      class="cursor-pointer !pr-0">
                close
            </mat-icon>

            <mat-icon matSuffix 
                      (click)="hideVisibilityNew = !hideVisibilityNew" 
                      class="cursor-pointer">
                {{hideVisibilityNew ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Neues Passwort wiederholen</mat-label>
            <input matInput formControlName="newPasswordRepeat" type="{{hideVisibilityNewRepeat ? 'password' : 'text'}}" >
            <mat-icon *ngIf="passwordForm.get('newPasswordRepeat')?.value" 
                      matSuffix
                      (click)="passwordForm.get('newPasswordRepeat')?.reset()"
                      class="cursor-pointer !pr-0">
                close
            </mat-icon>

            <mat-icon matSuffix 
                      (click)="hideVisibilityNewRepeat = !hideVisibilityNewRepeat" 
                      class="cursor-pointer">
                {{hideVisibilityNewRepeat ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </mat-form-field>

        <button mat-flat-button color="primary" type="submit" class="!h-14" [disabled]="passwordForm.invalid">
            Passwort ändern
        </button>

    </mat-card>

</form>