import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@core/layout/material/material.module';

import { HomeComponent } from './home/home.component';
import { UsersComponent } from './users/users.component';
import { CountOccupanciesComponent } from './count-occupancies/count-occupancies.component';
import { SpacesAutocompleteComponent } from './count-occupancies/spaces-autocomplete/spaces-autocomplete.component';
import { SpaceDetailsDialogComponent } from './count-occupancies/space-details-dialog/space-details-dialog.component';
import { HistoryComponent } from './history/history.component';
import { MeComponent } from './me/me.component';
import { UserDetailDialogComponent } from './users/user-detail-dialog/user-detail-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';


@NgModule({
	declarations: [
		HomeComponent,
		UsersComponent,
		CountOccupanciesComponent,
		SpacesAutocompleteComponent,
		SpaceDetailsDialogComponent,
		HistoryComponent,
		MeComponent,
		UserDetailDialogComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule
	]
})
export class AdminModule { }
