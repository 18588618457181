import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

	confirmationValue: string = ''
	confirmationForm: FormGroup

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		private formBuilder: FormBuilder
	) { }

	ngOnInit(): void {
		this.confirmationForm = this.formBuilder.group({
			confirmationValue: ['']
		});
	}

	checkDobuleConfirm(): boolean {
		// when double confirm is not set or set to FALSE
		if ( !this.data.doubleConfirm ) return false;
		// when confirmation text is correct
		if ( this.data.confirmationText === this.confirmationForm.get('confirmationValue').value ) return false
		// else stay enabled
		return true;
	}

	continue(): void {
		this.dialogRef.close({ data: 'confirmed' })
	}

}
