import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@core/layout/material/material.module';

import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { TranslatePipe } from './pipes/translate.pipe';

@NgModule({
	declarations: [
		ConfirmationDialogComponent,
		TranslatePipe
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule
	],
	exports: [
		TranslatePipe
	]
})
export class SharedModule { }
