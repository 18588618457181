<div class="grid place-items-center content-center h-full">

    <form [formGroup]="registerForm" id="ngForm" (keydown.enter)="$event.preventDefault()" class="max-w-xs" >

        <h1>Konto anlegen:</h1>

        <mat-form-field class="w-full">

            <mat-label>E-Mail</mat-label>

            <input matInput type="email" 
                   formControlName="emailAddress"
                   name="emailAddress" 
                   tabindex="1" />
        </mat-form-field>

        <mat-form-field class="w-full">

            <mat-label>Benutzername</mat-label>

            <input matInput type="text" 
                   formControlName="username"
                   name="username" 
                   tabindex="2" />
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Anrede</mat-label>
            <mat-select formControlName="salutation">
                <mat-option *ngFor="let option of salutationOptions" [value]="option.value">{{option.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">

            <mat-label>Vorname</mat-label>

            <input matInput type="text" 
                   formControlName="firstname"
                   name="firstname" 
                   tabindex="3" />
        </mat-form-field>

        <mat-form-field class="w-full">

            <mat-label>Nachname</mat-label>

            <input matInput type="text" 
                   formControlName="lastname"
                   name="lastname" 
                   tabindex="4" />
        </mat-form-field>

        <ng-container *ngIf="isInvited">

            <mat-form-field class="w-full">

                <mat-label>Altes Passwort</mat-label>
                    
                <input matInput type="password" 
                    formControlName="oldPassword"
                    type="{{hideVisibilityOld ? 'password' : 'text'}}" 
                    name="oldPassword" 
                    tabindex="2" />

                <mat-icon *ngIf="registerForm.get('oldPassword')?.value" 
                        matSuffix
                        (click)="registerForm.get('oldPassword')?.reset()"
                        class="cursor-pointer !pr-0">
                    close
                </mat-icon>

                <mat-icon matSuffix 
                        (click)="hideVisibilityOld = !hideVisibilityOld" 
                        class="cursor-pointer">
                    {{hideVisibilityOld ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                
            </mat-form-field>

            
            <mat-form-field class="w-full">

                <mat-label>Neues Passwort</mat-label>
                    
                <input matInput type="password" 
                    formControlName="newPassword"
                    type="{{hideVisibilityNew ? 'password' : 'text'}}" 
                    name="newPassword" 
                    tabindex="2" />

                <mat-icon *ngIf="registerForm.get('newPassword')?.value" 
                        matSuffix
                        (click)="registerForm.get('newPassword')?.reset()"
                        class="cursor-pointer !pr-0">
                    close
                </mat-icon>

                <mat-icon matSuffix 
                        (click)="hideVisibilityNew = !hideVisibilityNew" 
                        class="cursor-pointer">
                    {{hideVisibilityNew ? 'visibility_off' : 'visibility'}}
                </mat-icon>

                <mat-error *ngIf="registerForm.get('newPassword').hasError('matchValues')">
                    Die Passwörter stimmen nicht überein.
                </mat-error>
                
            </mat-form-field>

        </ng-container>

        <button type="submit" 
                name="submit" 
                mat-flat-button 
                color="primary" 
                class="w-full !h-14" 
                tabindex="7">
            Jetzt registrieren
        </button>

    </form>

    <p class="!mt-3">Ich habe bereits ein <a [routerLink]="'/login'">Konto</a>.</p>

</div>