import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';

@Component({
	selector: 'app-space-details-dialog',
	templateUrl: './space-details-dialog.component.html',
	styleUrls: ['./space-details-dialog.component.scss']
})
export class SpaceDetailsDialogComponent implements OnInit, OnDestroy {

    remainingTime: number;
    timerSub: Subscription;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: FormGroup
	) { }

	ngOnInit(): void {

        const timestamp: Date = this.data.get('timestamp').value;

        if ( timestamp ) {

            const endTime: number = new Date(timestamp).getTime() + 5 * 60 * 1000;

            this.remainingTime = endTime - Date.now();

            this.timerSub = timer(0, 1000).subscribe(() => {
                this.remainingTime = endTime - Date.now();
                if (this.remainingTime <= 0) {
                    this.remainingTime = 0;
                    this.data.get('blocked').patchValue(false)
                }
            });

        }

    }

    ngOnDestroy(): void {
        if ( this.timerSub ) this.timerSub.unsubscribe();
    }

}
