<h1 class="display-3 inline-flex items-center justify-start">
	<mat-icon class="mr-2">no_crash</mat-icon>
	Belegung zählen
</h1>

<form [formGroup]="form">

	<mat-card class="mat-elevation-z1 !rounded-none">
		<mat-card-content>
			<app-spaces-autocomplete></app-spaces-autocomplete>
		</mat-card-content>
	</mat-card>

	<mat-accordion class="example-headers-align">
		<mat-expansion-panel #panel class="mat-elevation-z1 !rounded-none !my-2" [disabled]="!hasSelectedSpace">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<mat-icon>map</mat-icon>
				</mat-panel-title>
				<mat-panel-description>
					Auf Karte anzeigen
				</mat-panel-description>
			</mat-expansion-panel-header>
			<ng-template matExpansionPanelContent>
				<ng-container *ngIf="hasSelectedSpace">
					<img width="100%"
						src="https://stadiamaps.com/static/osm_bright.jpg?center={{form.get('selectedSpace.geoLocation').value.latitude}},{{form.get('selectedSpace.geoLocation').value.longitude}}&zoom=17&m={{form.get('selectedSpace.geoLocation').value.latitude}},{{form.get('selectedSpace.geoLocation').value.longitude}},,fc0000,{{form.get('selectedSpace.label').value}},ffffff&size=350x350@2x&manual_attribution=true"
						class="!m-0 !p-0" />
				</ng-container>
			</ng-template>
		</mat-expansion-panel>
	</mat-accordion>

	<button type="button" mat-raised-button color="accent" class="w-full !h-14 uppercase mb-6" (click)="addItem()"
		[disabled]="!hasSelectedSpace">
		<mat-icon matListItemIcon>add</mat-icon> Zur Liste hinzufügen
	</button>

	<div formArrayName="spacesList" class="table-wrapper mb-24">

		<table mat-table [dataSource]="spacesList.controls" multiTemplateDataRows class="mat-elevation-z1">

			<!-- Count Column -->
			<ng-container matColumnDef="count">
				<th mat-header-cell *matHeaderCellDef class="!px-2">Belegung</th>
				<td mat-cell *matCellDef="let element" [formGroup]="element" class="!border-b-0 !pl-0 w-[4rem]">
					<mat-form-field class="count-input w-[4.5rem]">
						<input matInput type="number" pattern="[0-9]*" formControlName="count" class="max-w-[4rem]">
					</mat-form-field>
				</td>
			</ng-container>

			<!-- Name Column -->
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef class="!px-0">Name</th>
				<td mat-cell *matCellDef="let element" [formGroup]="element"
					class="!border-b-0 max-w-[8rem] !px-0 leading-4" (click)="openDetailDialog(element)">
					{{element.get('name').value}}
				</td>
			</ng-container>

			<!-- Capacity Column -->
			<ng-container matColumnDef="capacity">
				<th mat-header-cell *matHeaderCellDef class="!truncate !max-w-[4.5rem] sm:!max-w-full">Kapazität</th>
				<td mat-cell *matCellDef="let element" [formGroup]="element" class="!border-b-0 w-[3rem] !text-right">
					{{element.get('capacity').value}}
				</td>
			</ng-container>

			<!-- Position Column -->
			<ng-container matColumnDef="deleteRow">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let element" class="!border-b-0 w-[3rem] !px-0">
					<button mat-icon-button aria-label="Delete" (click)="removeItem(element.get('id').value)">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</ng-container>

			<ng-container matColumnDef="errorMessage">
				<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"
					class="!border-b-0 text-[0.85em] leading-3 align-top !pl-1">
					<mat-error *ngIf="element.get('count').hasError('required')">
						Dieses Feld muss ausgefüllt oder gelöscht werden.
					</mat-error>
					<mat-error *ngIf="element.get('count').hasError('maxCount')">
						Die maximale Kapazität für diesen Parkraum ist {{element.get('capacity').value}}.
					</mat-error>
					<mat-error *ngIf="element.hasError('serverError')">{{element.errors.message}}</mat-error>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
			<tr mat-row *matRowDef="let row; columns: ['errorMessage'];" [ngClass]="row.invalid ? '' : 'hidden'"
				class="!h-[1.6rem]"></tr>

			<!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
			<tr class="mat-row" *matNoDataRow>
				<td class="mat-cell p-5 text-center" colspan="4">Es wurden noch keine Parkräume hinzugefügt.</td>
			</tr>

		</table>

		<div class="flex justify-end text-xs mt-4">
			<button mat-raised-button (click)="removeAllItems()"><mat-icon>delete</mat-icon>Liste löschen</button>
		</div>

	</div>

	<mat-card
		class="mat-elevation-z1 z-10 !rounded-none !fixed !bottom-0 !w-full -ml-4 sm:!relative sm:!w-auto sm:ml-0 sm bg-gray-300">
		<mat-card-content>
			<button mat-flat-button color="primary" class="w-full !h-14 uppercase" (click)="onSubmit()"
				[disabled]="form.invalid || !spacesList.length">
				Zählungen hochladen
			</button>
		</mat-card-content>
	</mat-card>

</form>


<div class="hidden fixed top-16 left-0 bottom-0 max-w-[500px] overflow-scroll">
	<pre>{{form.value | json}}</pre>
</div>