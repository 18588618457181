import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '@shared/models/user.interface';
import { DataService } from '@core/services/data.service';
import { UserState } from '@core/states/user/user.state';
import { Select, Store } from '@ngxs/store';
import { debounceTime, Observable, Subject, takeUntil } from 'rxjs';
import { PaginationChanged, PaginationModel } from './states/pagination.action';
import { HistoryPaginationState } from './states/pagination.state';
import { RoleService } from '@core/services/role.service';
import { Role } from '@core/enums/role.enum';

@Component({
	selector: 'app-history',
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.scss'],
	providers: [DatePipe]
})
export class HistoryComponent implements OnInit, OnDestroy {

	queryForm: FormGroup;

	Role = Role;

	me: User;
	users: User[];

	displayedColumns: string[] = ['name', 'occupancyCounted', 'timestamp'];
	dataSource = new MatTableDataSource();
	totalElements: number;

	paginationParams: any
	
	destroy$: Subject<boolean> = new Subject<boolean>();
	
	@Select(HistoryPaginationState) HistoryPaginationState$: Observable<PaginationModel>

	@ViewChild(MatPaginator) paginator: MatPaginator;
  	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private dataService: DataService,
		private store: Store,
		private formBuilder: FormBuilder,
		private datePipe: DatePipe,
		public roleService: RoleService
	) { }

	ngOnInit(): void {

		this.paginationParams = this.store.selectSnapshot(HistoryPaginationState.getCurrentParams);
		this.me = this.store.selectSnapshot(UserState.getCurrentUser);

		if ( this.roleService.allowedRoles([Role.DISPATCHER]) ) this.getUsers();
		
		this.queryForm = this.formBuilder.group({
			from: new FormControl(''),
			until: new FormControl(''), // this.datePipe.transform(Date.now(), 'yyyy-MM-dd')
			selectedSpace: this.formBuilder.group({
				id: [null],
				name: [''],
				capacity: [null],
				geoLocation: {
					latitude: [null],
					longitude: [null]
				},
				label: ['']
			}),
			user: new FormControl({userId: this.me.id, username: this.me.username})
		});
		this.store.dispatch(new PaginationChanged({ size: 5, page: 0 }));
		// Subscribe to FilterParamater state and get Spaces
		this.HistoryPaginationState$
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe((data: PaginationModel) => { 
				if ( data ) {
					// Using spread because data is a read only object
					this.paginationParams = {...data};
					this.getCountedOccupancies();
				}
			})

		this.queryForm.valueChanges
			.pipe(
				takeUntil(this.destroy$),
				debounceTime(1000)
			)
			.subscribe(
				() => this.getCountedOccupancies() 
			)
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	getCountedOccupancies(): void {

		const user = this.queryForm.get('user').value;
		const spaceId = this.queryForm.get('selectedSpace.id').value;
		const from = this.datePipe.transform(this.queryForm.get('from').value, 'yyyy-MM-dd');
		const until = this.datePipe.transform(this.queryForm.get('until').value, 'yyyy-MM-dd');

		const params = Object.fromEntries(
			Object.entries({
			  ...this.paginationParams,
			  from: from,
			  until: until,
			  userId: user.userId,
			  spaceId: spaceId,
			}).filter(([_, value]) => value != null && value)
		);

		this.dataService.getCountedOccupancies(params).subscribe((data: any) => {
			this.dataSource.data = data.content;
			this.totalElements = data.totalElements;
		})
	}

	getUsers(): void {
		this.dataService.getUsers().subscribe((data: any) => {
			this.users = data.content.filter((user: User) => user.role === 'PARKSPOTTER')
		})
	}

	/** Dispatch paginator event */
	pageChanged(event: any) {
		this.paginationParams.size = event.pageSize
		this.paginationParams.page = event.pageIndex
		this.store.dispatch(new PaginationChanged(this.paginationParams))
	}

	/** Compare function for the mat-select component to determine 
	 * which option should be selected as the default value */
	compareFn(c1: any, c2: any): boolean {
		// Compare the userId property of the option objects to 
		// determine if they are equal
		return c1 && c2 ? c1.userId === c2.userId : c1 === c2;
	}
}
