<form  [formGroup]="confirmationForm">

    <h1 mat-dialog-title [innerHTML]="data.title"></h1>

    <div mat-dialog-content>
        
        <p [innerHTML]="data.text"></p>
        
        <mat-form-field *ngIf="data.doubleConfirm" appearance="outline">
            <mat-label>Tippen Sie "{{data.confirmationText}}" in dieses Feld</mat-label>
            <input matInput type="text" formControlName="confirmationValue" cdkFocusInitial>
        </mat-form-field>

    </div>

    <div mat-dialog-actions>
        <button mat-flat-button 
                mat-dialog-close>
                ABBRECHEN
        </button>
        <button mat-flat-button 
                mat-dialog-close 
                [color]="data.buttonColor || 'warn'"
                [disabled]="checkDobuleConfirm()" 
                (click)="continue()" cdkFocusInitial>
                {{data.confirmationText}}
        </button>
    </div>
    
</form>