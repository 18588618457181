<h1 class="inline-flex items-center justify-start">
    <mat-icon class="mr-2">timeline</mat-icon>
    Historie
</h1>

<p>Übersicht der historischen Daten.</p>

<mat-card class="mat-elevation-z1 mb-4 p-4 !rounded-none">

    <form [formGroup]="queryForm" (keydown.enter)="$event.preventDefault()">

        <mat-form-field class="w-full" *ngIf="roleService.allowedRoles([Role.DISPATCHER])">
            <mat-label>Parkspotter</mat-label>
            <mat-select formControlName="user" [compareWith]="compareFn">
                <mat-select-trigger>
                    {{queryForm.get('user').value.username}}
                </mat-select-trigger>
                <mat-option [value]="{userId: me.id, username: me.username}" class="my-2 leading-5">
                    <b>{{me.username}}</b><br>{{me.emailAddress}}
                </mat-option>
                <mat-option *ngFor="let user of users" [value]="{userId: user.id, username: user.username}" class="my-2 leading-5">
                    <b>{{user.username}}</b><br>{{user.emailAddress}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <app-spaces-autocomplete></app-spaces-autocomplete>

        <mat-form-field class="w-full mt-5" subscriptSizing="dynamic">
            <mat-label>Zeitspanne auswählen</mat-label>
            <mat-date-range-input 
                [rangePicker]="datepicker" 
                [comparisonStart]="queryForm.get('from').value"
                [comparisonEnd]="queryForm.get('until').value">
                <input matStartDate placeholder="Von" formControlName="from">
                <input matEndDate placeholder="Bis" formControlName="until">
            </mat-date-range-input>
            <mat-icon *ngIf="queryForm.get('until')?.value" 
                    matSuffix
                    (click)="queryForm.get('from')?.setValue(''); queryForm.get('until')?.setValue('');"
                    class="cursor-pointer !p-0">
            close
            </mat-icon>
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-date-range-picker #datepicker></mat-date-range-picker>
        </mat-form-field>
    </form>
    
</mat-card>

<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z1">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Parkraum </th>
        <td mat-cell *matCellDef="let element"> {{element.space.name}} </td>
    </ng-container>

    <!-- Occupancy Counted Column -->
    <ng-container matColumnDef="occupancyCounted">
        <th mat-header-cell *matHeaderCellDef> Gezählt </th>
        <td mat-cell *matCellDef="let element" class="!w-[2rem] !text-center"> {{element.occupancyCounted}} </td>
    </ng-container>

    <!-- Timestamp Column -->
    <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Datum </th>
        <td mat-cell *matCellDef="let element">
            {{element.timestamp | date: 'dd.MM.YYYY'}}<br>
            <small>{{element.timestamp | date: 'HH:mm \'Uhr\''}}</small>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center p-4" colspan="3">Keine Daten gefunden.</td>
    </tr>

</table>

<mat-paginator class="mat-elevation-z1 pb-3" [length]="totalElements" [pageSize]="paginationParams.size" [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="pageChanged($event)" showFirstLastButtons>
</mat-paginator>